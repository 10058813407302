.nav-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background-color: white;
}
.nav-flex-container > .flex-container > div {
  padding: 4px;
}
.nav-flex-container > .flex-container > div > a {
  color: #888;
}
.nav-flex-container > .flex-container > div > a:hover {
  color: #000;
  transition: .25s;
}
