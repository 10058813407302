* {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #999;
  font-weight: 400;
}

h1 {
  text-align: center;
  font-size: 40px;
}
h2 {
  font-size: 32px;
}
h4 {
  margin: 16px 0 0 0;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-container-spaced {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.column-flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section {
  padding: 64px 24px;
}
.content-section {
  font-size: 22px;
  line-height: 1.5;
  max-width: 600px;
  text-align: center;
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 24px;
  }
}
@media screen and (max-width: 750px) {
  .content-section {
    font-size: 16px;
  }
}

