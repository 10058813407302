.work-tile {
  position: relative;
  max-width: 80vw;
  width: 100%;
  height: 80vw;
  padding: 24px;
  background-color: #f4f3f1;
  border-radius: 8px;
  border: 0px;
  margin: 24px;
  justify-content: center;
  text-align: center;
}
.work-tile:hover {
  cursor: pointer;
}
.work-tile > img {
  border-radius: 8px;
  width: 80%;
  position: relative;
  top: 0;
  transition: .5s;
  box-shadow: 0px 20px 20px #a59d8d;
}
.work-tile:hover > img {
  box-shadow: 0px 20px 30px #726a5a;
  transition: .5s;
  top: -10px;
}

@media screen and (min-width: 990px) {
  .work-tile {
    max-width: 40vw;
    height: 40vw;
  }
}
@media screen and (min-width: 1200px) {
  .work-tile {
    max-width: 29vw;
    height: 29vw;
  }
}